<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="card card-custom">
        <div class="card-body p-0">
          <!--begin: Wizard-->
          <div
            class="wizard wizard-1"
            id="kt_wizard_v1"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <!--begin: Wizard Body-->
            <div
              class="row justify-content-center my-10 px-8 my-lg-10 px-lg-8"
            >
              <div class="col-xl-12">
                <!--begin: Wizard Form-->
                <Form
                  :form="form"
                  :title="'Tambah Moderator'"
                  purpose="add"
                  role="moderator"
                  route="api/members"
                />
                <!--end: Wizard Form-->
              </div>
            </div>
            <!--end: Wizard Body-->
          </div>
        </div>
        <!--end: Wizard-->
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/view/components/members/Form.vue";

export default {
  components: {
    Form,
  },
  data() {
    return {
      form: {
        name :"",
        phone :"",
        email :"",
        gender :"",
        business_name :"",
        business_address :"",
        business_city :"",
        postal_code :"",
        business_estabilished_age :"",
        business_legal_type :"",
        industry_type :"",
        industry_type :"",
        monthly_omset :"",
        youtube_link :"",
        is_partner:"",
        member_role_id :"",
        photo_url:"",
        company_logo_url:"",
        products: [],
        password: "virtual",
        password_confirmation: "virtual"
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Anggota", route: "/members" },
      { title: "Tambah" },
    ]);
  },
};
</script>